import React, { useState, useEffect } from 'react';
import JSONEditor from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import BlogList from '../Components/BlogList';

const JsonEditorPage = () => {
  const [jsonData, setJsonData] = useState({
    id: '',
    title: '',
    author: '',
    content: '',
    date: '',
    featured: false
  });
  const [savedBlogs, setSavedBlogs] = useState([]);
  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const API_BASE_URL = 'https://droshpod.com/api';

  useEffect(() => {
    fetchBlogs();
    fetchFeaturedBlogs();
  }, [refresh]);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/list-blogs.php`);
      if (response.ok) {
        const blogs = await response.json();
        setSavedBlogs(blogs);
      } else {
        console.error('Error fetching blogs');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchFeaturedBlogs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/featured-blogs.php`);
      if (response.ok) {
        const featured = await response.json();
        setFeaturedBlogs(featured);
      } else {
        console.error('Error fetching featured blogs');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSave = async () => {
    const currentDate = new Date().toISOString();
    const updatedJsonData = { ...jsonData, date: currentDate };
    
    try {
      const response = await fetch(`${API_BASE_URL}/save-blog.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedJsonData),
      });
      
      if (response.ok) {
        alert('Blog saved successfully!');
        setJsonData({
          id: '',
          title: '',
          author: '',
          content: '',
          date: '',
          featured: false
        });
        setRefresh(!refresh);
      } else {
        alert('Error saving blog');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error saving blog');
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setJsonData({ 
      ...jsonData, 
      [name]: type === 'checkbox' ? checked : value 
    });
  };

  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setJsonData(data.jsObject);
    }
  };

  const handleSelectBlog = (blog) => {
    setJsonData(blog);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Blog Editor</h1>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
          Title
        </label>
        <input
          id="title"
          name="title"
          type="text"
          value={jsonData.title}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="author">
          Author
        </label>
        <input
          id="author"
          name="author"
          type="text"
          value={jsonData.author}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="content">
          Content
        </label>
        <textarea
          id="content"
          name="content"
          value={jsonData.content}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="10"
        />
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="featured"
            checked={jsonData.featured}
            onChange={handleInputChange}
            className="mr-2"
          />
          <span className="text-gray-700 text-sm font-bold">Featured Post</span>
        </label>
      </div>
      <div className="mb-4">
        <h2 className="text-lg font-bold mb-2">JSON Preview</h2>
        <JSONEditor
          placeholder={jsonData}
          locale={locale}
          onChange={handleJsonChange}
          height="200px"
        />
      </div>
      <button 
        onClick={handleSave}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Save Blog
      </button>

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Featured Blogs</h2>
        <ul>
          {featuredBlogs.map((blog) => (
            <li key={blog.id} className="mb-2">
              <button onClick={() => handleSelectBlog(blog)} className="text-blue-500 hover:underline">
                {blog.title} by {blog.author} ({new Date(blog.date).toLocaleString()})
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">All Blogs</h2>
        <ul>
          {savedBlogs.map((blog) => (
            <li key={blog.id} className="mb-2">
              <button onClick={() => handleSelectBlog(blog)} className="text-blue-500 hover:underline">
                {blog.title} by {blog.author} ({new Date(blog.date).toLocaleString()})
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-8">
        <BlogList refresh={refresh} />
      </div>
    </div>
  );
};

export default JsonEditorPage;