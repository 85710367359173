import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../pics/FOOT ICON2.svg';

export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="fixed w-full bg-white/90 backdrop-blur-sm shadow-sm z-50">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                        <Link className="flex items-center hover:opacity-80 transition-opacity" to="/">
                            <img src={Logo} alt="Logo" className="w-10 lg:w-12" />
                            <div className="font-sans -ml-4">
                            <h1 className='text-lg leading-none lg:text-md'>Drosh</h1>
                                <h1 className='text-sm font-thin leading-none lg:text-md'>The</h1>
                                <h1 className='text-lg leading-none text-primary lg:text-md'>POD</h1>
                            </div>
                        </Link>
                    </div>

                    <button
                        className="md:hidden rounded-lg p-2 hover:bg-gray-100 transition-colors"
                        onClick={toggleMenu}
                        aria-label="Toggle mobile menu"
                    >
                        <svg
                            className="w-6 h-6 text-gray-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {isMenuOpen ? (
                                <path d="M18 6L6 18M6 6l12 12" />
                            ) : (
                                <path d="M4 6h16M4 12h16M4 18h16" />
                            )}
                        </svg>
                    </button>

                    <nav className="hidden md:flex items-center gap-8">
                        <Link
                            className="text-gray-600 hover:text-black transition-colors text-sm font-medium"
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            className="text-gray-600 hover:text-black transition-colors text-sm font-medium"
                            to="/services"
                        >
                            Treatments
                        </Link>
                        <Link
                            className="text-gray-600 hover:text-black transition-colors text-sm font-medium"
                            to="/about"
                        >
                            About
                        </Link>
                        <Link
                            className="text-gray-600 hover:text-black transition-colors text-sm font-medium"
                            to="/Blog"
                        >
                            Blog
                        </Link>
                    </nav>
                </div>
            </div>

            <div 
                className={`
                    absolute top-16 left-0 right-0 
                    bg-white/90 backdrop-blur-sm
                    shadow-lg transform transition-all duration-300 ease-in-out
                    ${isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}
                    md:hidden
                `}
            >
                <nav className="flex flex-col p-4">
                    <Link
                        className="py-2 px-4 text-gray-600 hover:text-black hover:bg-gray-50 rounded-lg transition-colors"
                        to="/"
                        onClick={toggleMenu}
                    >
                        Home
                    </Link>
                    <Link
                        className="py-2 px-4 text-gray-600 hover:text-black hover:bg-gray-50 rounded-lg transition-colors"
                        to="/services"
                        onClick={toggleMenu}
                    >
                        Treatments
                    </Link>
                    <Link
                        className="py-2 px-4 text-gray-600 hover:text-black hover:bg-gray-50 rounded-lg transition-colors"
                        to="/about"
                        onClick={toggleMenu}
                    >
                        About
                    </Link>
                    <Link
                        className="py-2 px-4 text-gray-600 hover:text-black hover:bg-gray-50 rounded-lg transition-colors"
                        to="/about"
                        onClick={toggleMenu}
                    >
                        Blog
                    </Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;