import React from 'react';

const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="inline-block w-5 h-5 fill-current text-yellow-500">
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
  </svg>
);

const RatingSubtitle = () => (
  <h2 className="text-center text-lg md:text-xl font-semibold text-gray-800 flex flex-col items-center justify-center">
    <div className="flex items-center space-x-1">
      {[...Array(5)].map((_, index) => (
        <StarIcon key={`star-${index}`} aria-hidden="true" role="presentation" />
      ))}
    </div>
    <span className="mt-2">
      Rated <strong>5.0</strong> by our satisfied patients
    </span>
  </h2>
);

export default RatingSubtitle;