import React from 'react';

const Col2Component = ({ title, description, imageProfile }) => {
  return (
    <div className=" m-14 grid grid-cols-1 md:grid-cols-2 gap-4">

      <div className="flex justify-center items-center">
        <img src={imageProfile} alt={title} className="w-60 h-60 rounded-full  shadow-lg object-cover" />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className='font-bold text-4xl p-5 text-secondary'>{title}</h1>
        <p>{description}</p>
      </div>    
    </div>
  );
};

export default Col2Component;
