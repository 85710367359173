import React, { useContext } from 'react';
import { DataContext } from './DataContext';
import { ChakraProvider, SimpleGrid, Box, Heading, Image } from '@chakra-ui/react';
import LoadingComponent from './LoadingComponent.jsx';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ServiceList = () => {
  const data = useContext(DataContext);

  if (!data) {
    return <LoadingComponent />;
  }

  const { PodCategory } = data;

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
  };

  return (
    <ChakraProvider>
      <div className=" flex justify-center items-center px-2 py-1 my-4">
        <motion.div
          className="container mx-auto max-w-6xl"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <SimpleGrid
            spacing={2}
            columns={{ base: 1, sm: 2, lg: 4 }}
            maxWidth="1200px"
            mx="auto"
            my={2}
          >
            {PodCategory.map((service) => (
              <motion.div key={service.id} variants={itemVariants}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.9, ease: "easeOut" }}
                style={{ aspectRatio: '1/1', width: '100%' }}
              >
                <Box
                  as={Link}
                  to={service.Link}
                  position="relative"
                  width="100%"
                  height="100%"
                  overflow="hidden"
                  transition="transform 0.7s ease-in-out"
                  _hover={{ transform: 'scale(1.05)' }}
                >
                  <Image
                    src={service.serviceImage}
                    alt={service.serviceTitle}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    borderRadius="2xl"
                  />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    p={2}
                    background="linear-gradient(to bottom, rgba(0,0,0,0.7), transparent)"
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    height="50%"
                    zIndex={1}
                    borderTopRadius="2xl"
                  >
                    <Heading
                      size="md"
                      color="white"
                      textAlign="center"
                      textShadow="2px 2px 4px rgba(0,0,0,0.5)"
                      roundedBottom={"md"}
                    >
                      {service.serviceTitle}
                    </Heading>
                  </Box>
                </Box>
              </motion.div>
            ))}          </SimpleGrid>
        </motion.div>
      </div>
    </ChakraProvider>
  );
};

export default ServiceList;

/* Previous versions:

Version 1 - Initial Grid Layout:
<SimpleGrid columns={[1, 2, 4]} spacing={4}>
  {PodCategory.map((service) => (
    <Box key={service.id}>
      <Image src={service.serviceImage} />
      <Heading>{service.serviceTitle}</Heading>
    </Box>
  ))}
</SimpleGrid>

Version 2 - Added Link and Basic Hover:
<Box
  as={Link}
  to={service.Link}
  _hover={{ transform: 'scale(1.05)' }}
>
  <Image src={service.serviceImage} />
  <Heading>{service.serviceTitle}</Heading>
</Box>

Version 3 - Added Motion Effects:
<motion.div
  variants={containerVariants}
  initial="hidden"
  animate="show"
>
  <SimpleGrid columns={[1, 2, 4]} spacing={4}>
    {PodCategory.map((service) => (
      <motion.div variants={itemVariants}>
        <Box as={Link} to={service.Link}>
          <Image src={service.serviceImage} />
          <Heading>{service.serviceTitle}</Heading>
        </Box>
      </motion.div>
    ))}
  </SimpleGrid>
</motion.div>

Version 4 - Added Gradient Overlay:
<Box
  position="absolute"
  bottom="0"
  background="linear-gradient(to top, rgba(0,0,0,0.8), transparent)"
>
  <Heading color="white">{service.serviceTitle}</Heading>
</Box>
*/
