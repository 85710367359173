import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import Col2Component from '../Components/2-col-section-';
import manchesterclinic from '../pics/manchesterclinic.png';
import ProfilePic from '../pics/Placeholder_profile.jpg';
import { DataContext } from '../Components/DataContext';
import '../index.css';
import WavyLine from '../Components/wavyline';
import CareImg from '../pics/Handshake Heart.png'
import MissionImg from '../pics/Goal.png'
import PromiseImg from '../pics/Agreement.png'

function AboutPage() {
  const contextData = useContext(DataContext);

  if (!contextData || !contextData.AddressInfo || !contextData.AboutMe) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const { AddressPart1, AddressPart2, AddressPart3, AddressPart4, GoogleAPImaps} = contextData.AddressInfo;
  const { AboutMeTitle, AboutMeDesc } = contextData.AboutMe;

  return (
    <div className="min-h-screen">
      <div className="absolute inset-0 top-[650px] overflow-hidden -z-10">
        <WavyLine/>
      </div>
      <div className="flex flex-col mt-10 lg:flex-row min-h-[20vh] max-h-[60vh]">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="w-full lg:w-1/2 relative overflow-hidden min-h-[300px]"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-transparent to-white z-10"></div>
            <img className="w-full h-full object-cover " src={manchesterclinic} alt="Service Room" />   
          </motion.div>
          <div className="w-full lg:w-1/2 p-6 flex items-center justify-center">
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1.5, ease: "easeOut" }}
              className="text-center lg:text-left">
              <h1 className="text-4xl lg:text-6xl font-bold text-black tracking-tight mb-4">About Us</h1>
              <p className="text-gray-600 text-xl lg:text-2xl">Manchester Based Podiatry Clinic</p>
            </motion.div>
          </div>
        </div>
      
      <Col2Component
        title={AboutMeTitle}
        description={AboutMeDesc}
        imageProfile={ProfilePic}
      />

      <div className="relative py-20 px-4">
        <div className="absolute left-0 w-full h-full transform -skew-y-6 bg-gray-100 -z-10"></div>
        <div className="container mx-auto">
          <div className="flex flex-col space-y-8 max-w-4xl mx-auto">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.2, ease: "easeOut" }}
              viewport={{ once: true, margin: "-100px" }}
              className="flex flex-col md:flex-row items-center gap-4 transform hover:scale-105 transition-all duration-500 ease-in-out"
            >
              <div className="bg-white p-8 rounded-2xl shadow-xl w-full md:max-w-md">
                <h2 className="text-2xl lg:text-3xl font-bold mb-4">Our Care</h2>
                <p className="text-gray-600 text-lg">Dedicated to providing exceptional podiatric care with a patient-centered approach.</p>
              </div>
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg">
                <img className='w-10' src={CareImg} alt="Care" />  
              </div>
            </motion.div>
            
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.2, ease: "easeOut", delay: 0.2 }}
              viewport={{ once: true, margin: "-100px" }}
              className="flex flex-col md:flex-row items-center gap-4 md:ml-12 transform hover:scale-105 transition-all duration-500 ease-in-out"
            >
              <div className="bg-white p-8 rounded-2xl shadow-xl w-full md:max-w-md">
                <h2 className="text-2xl lg:text-3xl font-bold mb-4">Our Mission</h2>
                <p className="text-gray-600 text-lg">To improve the quality of life through expert foot care and personalized treatment plans.</p>
              </div>
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg">
                <img className='w-10' src={MissionImg} alt="Mission" />  
              </div>
            </motion.div>
            
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.2, ease: "easeOut", delay: 0.4 }}
              viewport={{ once: true, margin: "-100px" }}
              className="flex flex-col md:flex-row items-center gap-4 md:ml-24 transform hover:scale-105 transition-all duration-500 ease-in-out"
            >
              <div className="bg-white p-8 rounded-2xl shadow-xl w-full md:max-w-md">
                <h2 className="text-2xl lg:text-3xl font-bold mb-4">Our Promise</h2>
                <p className="text-gray-600 text-lg">Ensuring the highest standards of care with modern techniques and compassionate service.</p>
              </div>
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg">
                <img className='w-10' src={PromiseImg} alt="Our Promise" />  
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className='container mx-auto px-4 py-16'>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className='space-y-8'
        >
          <div className='w-full aspect-video'>
            <iframe
              src={GoogleAPImaps}
              className='w-full h-full rounded-3xl shadow-xl'
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Manchester Clinic Location"
            />
          </div>
          <div className='text-right space-y-4 px-4 lg:px-20'>
            <h1 className='text-3xl lg:text-4xl font-bold'>Address</h1>
            <div className='text-lg lg:text-xl space-y-2'>
              <p>{AddressPart1}</p>
              <p>{AddressPart2}</p>
              <p>{AddressPart3}</p>
              <p>{AddressPart4}</p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default AboutPage;