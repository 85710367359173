import React from 'react';

const Col2ComponentPH = ({ description, title, imgSrc, imgRight = false }) => {
  return (
    <div className="m-14 grid grid-cols-1 md:grid-cols-2 gap-4">
      {!imgRight && (
        <img src={imgSrc} alt={title} className="object-cover w-full h-full rounded-lg" />
      )}
      <div className="flex flex-col justify-center items-start p-4">
        <h1 className="font-bold text-4xl text-secondary mb-4">{title}</h1>
        <p className="text-lg text-gray-500">{description}</p>
      </div>
      {imgRight && (
        <img src={imgSrc} alt={title} className="object-cover w-full h-full rounded-lg" />
      )}
    </div>
  );
};

export default Col2ComponentPH;
