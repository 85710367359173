import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const API_URL = 'http://localhost:3001/api';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPost();
  }, [id]);

  const fetchPost = async () => {
    try {
      const response = await fetch(`${API_URL}/blog/${id.split('-')[0]}`);
      if (response.ok) {
        const data = await response.json();
        setPost(data);
      } else {
        console.error('Error fetching blog post');
      }
    } catch (error) {
      console.error('Error fetching blog post:', error);
    }
  };

  if (!post) return <div className="text-center p-4">Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8 relative">
      <button
        onClick={() => navigate('/blog')}
        className="fixed top-19 left-4 z-10 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold p-2 sm:p-3 rounded-full shadow-md transition duration-300 ease-in-out"
        aria-label="Back to blog list"
      >
        <FaArrowLeft className="text-sm sm:text-base" />
      </button>
      <article className="max-w-3xl mx-auto bg-white p-4 sm:p-8 rounded-lg shadow-md mt-12 sm:mt-16">
        <header className="mb-4 sm:mb-6">
          <h1 className="text-2xl sm:text-4xl font-bold mb-2 sm:mb-4">{post.title}</h1>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between text-gray-600">
            <p className="text-base sm:text-lg">By {post.author}</p>
            <p className="text-sm">{new Date(post.date).toLocaleDateString()}</p>
          </div>
        </header>
        <section className="prose prose-sm sm:prose-base lg:prose-lg max-w-none">
          {post.content.split('\n').map((paragraph, index) => (
            <p key={index} className="mb-4">{paragraph}</p>
          ))}
        </section>
      </article>
    </div>
  );
};

export default BlogPost;