import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel1 from "../pics/BGmain1.jpg";
import { DataContext } from '../Components/DataContext';

const CarouselComponent = () => {
  const data = useContext(DataContext);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  if (!data) {
    return <div>Loading...</div>;
  }

  const { slides } = data;

  const updateCurrentSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative my-24 mx-16">
      <div className="relative rounded-3xl overflow-hidden">
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={4000}
          transitionTime={500}
          showArrows={false}
          dynamicHeight={false}
          showIndicators={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
        >
          {slides.map((slide, index) => (
            <div key={index} className='carousel-slide' style={{
              backgroundImage: `url(${Carousel1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: 'clamp(100px, 30vh, 200px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: 'blur(5px)',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            }}>
              <div className="flex flex-col items-center justify-center h-full">
                <h2 className="text-white lg:text-4xl sm:text-xl mb-4">{slide.title}</h2>
                <img 
                  className={`w-auto h-16 lg:h-24 object-contain ${slide.alt === "Northern Care Alliance" ? "filter grayscale" : "mix-blend-color-dodge"}`}
                  src={slide.image}
                  alt={slide.alt}
                />
              </div>
            </div>
          ))}
        </Carousel>

        <div className="absolute left-[20px] top-1/2 transform -translate-y-1/2 flex flex-col">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => updateCurrentSlide(index)}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: currentSlide === index ? 'white' : '#666',
                marginBottom: '8px',
                cursor: 'pointer',
                border: 'none'
              }}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;