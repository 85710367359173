import React from 'react';
import Hero from '../Components/Section1-Main';
import Badges from '../Components/Section-Cards';
import CarouselComponent from '../Components/Carousel.jsx';
import '../index.css';
import FAQComponent from '../Components/FAQ';
import OpeningTimes from '../Components/2-col-section-OP';
import ServiceBox from '../Components/serviceList.jsx';
import BlogSlider from '../Components/BlogSlider.jsx';
import { lazy, Suspense } from 'react';


function MainPage() {

    return (
        <div>
            
            <Hero />
            <Badges />
            <ServiceBox/>
            <Suspense fallback={<div>Loading...</div>}>
            <CarouselComponent />
            <OpeningTimes />
            <FAQComponent/>
            <BlogSlider />
            </Suspense>

        </div>
    );
}
export default MainPage;
