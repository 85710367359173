import React, { useEffect, useState } from 'react';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await fetch('https://droshpod.com/api/blogs/list.php');
      const data = await response.json();
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="blog-list">
      <h2>Blog Posts</h2>
      <div className="grid gap-4">
        {blogs.map(blog => (
          <div key={blog.id} className="border p-4 rounded">
            <h3>{blog.title}</h3>
            <p>{blog.content.substring(0, 200)}...</p>
            <span>{new Date(blog.date).toLocaleDateString()}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;