import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ServicesROOM from '../pics/podroom.webp';
import '../index.css';
import servicesData from '../data.json';
import WavyLine from '../Components/wavyline';

function ServicePage() {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceChange = (service) => {
    setSelectedService(service === selectedService ? null : service);
  };

  const services = servicesData.PodService;

  return (
    <div className="w-full bg-white min-h-screen flex flex-col overflow-hidden">
      <div className="flex flex-col md:flex-row min-h-[10vh] items-center justify-center">
        <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}

            className="text-center"
          >
            <h1 className="text-4xl lg:text-6xl font-bold text-black tracking-tight mb-4">Services</h1>
            <p className="text-gray-600 text-xl lg:text-2xl">Professional Podiatry Support</p>
          </motion.div>
        </div>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
          className="w-full md:w-1/2 relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-l from-transparent to-white z-10"></div>
          <img className="w-full h-full object-cover" src={ServicesROOM} alt="Service Room" />   
        </motion.div>
      </div>
      <div className="absolute inset-0 top-[470px] overflow-hidden -z-5">
        <WavyLine/>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-16 flex-grow">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 gap-y-6">
          {services.map((service) => (
            <motion.div 
              key={service.id} 
              className="bg-white rounded-2xl shadow-md hover:shadow-lg transition-all duration-300 relative z-10 overflow-hidden"
              whileHover={{ scale: 1.005 }}
              transition={{ duration: 0.2 }}
            >
              <button 
                className="w-full p-8 flex justify-between items-center focus:outline-none bg-white rounded-2xl"
                onClick={() => handleServiceChange(service.id)}
              >
                <h3 className="text-xl md:text-2xl font-semibold text-gray-800">
                  {service.serviceTitle}
                </h3>
                <div className="flex items-center space-x-4">
                  <span className="text-lg pl-24 font-bold text-primary">{service.serviceCost}</span>
                  <svg 
                    className={`w-6 h-6 transform transition-transform duration-200 ${selectedService === service.id ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </button>
              <AnimatePresence>
                {selectedService === service.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden bg-white relative z-20 rounded-b-2xl"
                  >
                    <div className="px-6 pb-4 text-gray-600">
                      {service.serviceDescription}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}        </div>      </div>
    </div>
  );

}export default ServicePage;