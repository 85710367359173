import React from 'react';
import {  ChakraProvider, Spinner,  Center,} from '@chakra-ui/react';

const LoadingComponent = () => {
  return (
    <ChakraProvider>
    <Center height="100vh">
      <Spinner size="xl" />
    </Center>
  </ChakraProvider>
  );
};

export default LoadingComponent;