import React, { useContext } from 'react';


function NotFoundPage() {
  return(
  <div>
    
    </div>
  );
}

export default NotFoundPage;
