import React from 'react';
import BlogGrid from '../Components/BlogGrid';
import Wavyline from '../Components/wavyline';

const Blog = () => {
  return (
    <div>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Our Blog</h1>
      <BlogGrid />

    </div>
    <Wavyline/>
    </div>
  );
};

export default Blog;
