import React from "react";
import Badge1IMG from "../pics/circle check.svg";
import Badge2IMG from "../pics/intel.svg";
import Badge3IMG from "../pics/plusnotenote+.svg";
import Badge4IMG from "../pics/house.svg";
import { motion } from 'framer-motion';
import BadgeComponent from "./BadgeComponents";

const badgesData = [
  { imageSrc: Badge1IMG, title: "Guaranteed", description: "Qualified Assessments" },
  { imageSrc: Badge2IMG, title: "Services", description: "Fitted To Your Needs" },
  { imageSrc: Badge3IMG, title: "Availability", description: "Flexible Appointments" },
  { imageSrc: Badge4IMG, title: "Local To", description: "Greater Manchester" },
];

export const Badges = () => {
  return (
    <motion.div 
      initial={{ y: 120, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="mx-2 sm:mx-5 lg:mx-12 -my-8">
        <ul className="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto my-16 max-w-6xl">
          {badgesData.map((badge, index) => (
            <li key={index} className="flex justify-center p-2 overflow-visible min-h-[120px] relative z-10">
              <BadgeComponent
                imageSrc={badge.imageSrc}
                title={badge.title}
                description={badge.description}
              />
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default Badges;
