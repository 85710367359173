import React from 'react';
import FAQData from '../data.json'; // Import the JSON file containing FAQs


const FAQComponent = () => {
  const { FAQ } = FAQData;

  return (
    <section className="px-8 py-10">
      <div className="container mx-auto">
        <div className="mb-14 text-center">
          {/* <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">         Frequently asked questions      </h1> */}
          <h1 className="text-4xl font-bold text-secondary mb-8 text-center">Frequenly Ask Questions</h1>

        </div>
        <div className="max-w-3xl mx-auto grid gap-10">
          {Object.values(FAQ).map(({ title, desc, link, Link_Text }) => (
            <div key={title}>
              <h1 color="blue-gray" className="pb-6 text-[20px] font-bold">
                {title}
              </h1>
              <div className="border-t border-gray-200 pt-4">
                <p className="font-normal !text-gray-500">
                  {desc}<br/>
                  <a className=" font-bold text-secondary" href={link}>{Link_Text}</a>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQComponent;
