import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_URL = 'http://localhost:3001/api';

const BlogSlider = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${API_URL}/featured-blogs`);
        if (response.ok) {
          const data = await response.json();
          setBlogs(data);
        } else {
          console.error('Error fetching featured blogs');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchBlogs();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    <div className="carousel-container">
      <h1 className="text-3xl font-bold text-secondary m-10 mb-6">Our Blog</h1>
      <Slider {...settings}>
        {blogs.map((blog) => (
          <div key={blog.id} className="px-2  mb-20">
            <Link to={`/blog/${blog.id}`} className="block">
              <div className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-xl font-bold mb-2 truncate">{blog.title}</h3>
                <p className="text-gray-600 mb-4 truncate">By {blog.author}</p>
                <p className="text-gray-700 line-clamp-3">{blog.content}</p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogSlider;