// DataContext.js
import React, { createContext, useState, useEffect } from 'react';
import data from '../data.json';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [appData, setAppData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setAppData(data);
    }, 1000); // Simulating a delay
  }, []);

  return (
    <DataContext.Provider value={appData}>
      {children}
    </DataContext.Provider>
  );
};
