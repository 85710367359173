import React, { useContext } from 'react';
import OrthoticsBanner from '../pics/PODBANNER.jpg';
import COL from '../Components/2-col-section-PH';
import { DataContext } from '../Components/DataContext';


function OrthoticsPage() {
  const data = useContext(DataContext);

  if (!data) {
    return <div>Loading...</div>;
  }

  const { OrthoticsInfo } = data;
  const { OrthTitle, OrthDesc } = OrthoticsInfo[0];

  return (

    <div>
      <div className="container mx-auto p-4 pt-8">
        <img src={OrthoticsBanner} alt="orthotics" className="w-full h-fill object-cover rounded-lg" />
        <div className="flex flex-col  py-8">
          <h1 className="text-4xl font-bold text-secondary">Orthotics In Manchester</h1>
          <p className="text-lg text-gray-500">At Drosh's Clinic, we understand the importance of orthotics in helping to alleviate pain and discomfort caused by various foot conditions. Our experienced podiatrists will assess your foot and provide you with a tailored orthotics treatment plan to help you achieve optimal foot health.</p>
        </div>

      </div>
      <COL
        title={OrthTitle}
        description={OrthDesc} 
        imgSrc={OrthoticsBanner}
        imgRight={false} />


    </div>


  );
};

export default OrthoticsPage;
