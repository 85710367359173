import { Html, Head, Body, Container, Section, Text } from '@react-email/components';
import React from 'react';

export const ContactFormEmail = ({ name, phone, email, postcode }) => (
  <Html>
    <Head />
    <Body style={main}>
      <Container>
        <Section>
          <Text style={heading}>New Contact Form Submission</Text>
          <Text>Name: {name}</Text>
          <Text>Phone: {phone}</Text>
          <Text>Email: {email}</Text>
          <Text>Postcode: {postcode}</Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

const main = {
  backgroundColor: '#ffffff',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const heading = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '20px',
};

export default ContactFormEmail;