// OpeningTimes.js
import React, { useContext } from 'react';
import { DataContext } from '../Components/DataContext';
import CustomButton from './Button';
import Wavyline from '../Components/wavyline';

const OpeningTimes = () => {
  const data = useContext(DataContext);

  if (!data) {
    return <div>Loading...</div>;
  }

  const { operatingTimes } = data;

  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
          <div className="absolute inset-0 top-1/2 overflow-hidden -z-10">
          <Wavyline  />

            <svg className="w-full h-full" aria-hidden="true" focusable="false">
              <defs>
              </defs>
              {/* <rect width="100%" height="100%" fill="url(#gradient)" opacity="0.1"/> */}
            </svg>
          </div>
          <h1 className="text-4xl font-bold text-secondary mb-8 text-center">Operating Times</h1>
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="p-8 bg-white rounded-2xl shadow-lg transition-transform z-10">
          <ul className="text-lg space-y-4">
            {Object.entries(operatingTimes).map(([day, time]) => (
              <li className="flex justify-between items-center border-b border-gray-100 pb-3" key={day}>
                <span className="font-medium text-gray-800">{day}</span>
                <span className="text-secondary">{time}</span>
              </li>
            ))}
          </ul>
        </div>
        {/* Changed positioning for mobile - now shows below times on small screens */}
        <div className="md:absolute md:right-0 w-full md:w-[65%] h-full bg-white bg-opacity-90 rounded-2xl shadow-lg">
          <div className="flex flex-col justify-center items-center h-full p-8 md:pl-24">
            <h2 className="text-2xl font-semibold text-secondary mb-4 text-center">Ready to Visit?</h2>
            <p className="text-gray-600 mb-2 text-2xl text-center max-w-[75%] italic">Book your consultation today and take the first step towards your appointment.</p>
            <CustomButton
              to="/ConsultationForm"
              title="Get In Touch"
              accessibilityLabel="Get In Touch"
              className="bg-gradient-to-r from-secondary to-primary text-white px-8 py-2 rounded-full font-semibold shadow-lg"
            /></div>
        </div>
      </div>
    </div>
  );
};
export default OpeningTimes;