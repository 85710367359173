import React from "react";

export const BadgeComponent = ({ imageSrc, title, description }) => {
  return (
    <div className="text-center p-3  w-full max-w-xs">
      <img src={imageSrc} alt={title} className="w-10 mx-auto" />
      <h1 className="text-md mt-2 font-bold">{title}</h1>
      <p className="text-sm overflow-hidden h-8">{description}</p>
    </div>
  );
};

export default BadgeComponent;
