import React from 'react';
import { Link } from 'react-router-dom';

const CustomButton = ({ to, title, accessibilityLabel }) => {
  return (
    <div className='flex justify-center w-fit rounded-md p-2 text-xl text-white m-auto items-center bg-primary'>
    <Link to={to} className="button" title={title} aria-label={accessibilityLabel}>
      {title}
    </Link>
    </div>
  );
};

export default CustomButton;